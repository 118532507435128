import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import { Fade } from "react-awesome-reveal";
class Footer extends Component {
  render() {
    //let cmsData = this.props.cmsData;
    return (
      <section className='footer-area'>
        <div className='container'>
          <div className='footer-content'>
            <Link to='/' className='logo wow fadeInDown' data-wow-delay='0.4s'>
              <img src={require('../image/logo2.png')} alt='' />
            </Link>
            {/* <Fade direction="down" cascade triggerOnce>
              <ul className="list_style">
                {cmsData.socialLinks.map((item) => {
                  return (
                    <li key={item.name}>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <i className={item.className}></i>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Fade> */}

            {/* <h6>Made with love and JavaScript</h6> */}
            {/* <p>
              © {new Date().getFullYear()} Pascal Cantaluppi - All Rights
              Reserved
            </p> */}
          </div>
        </div>
      </section>
    );
  }
}
export default Footer;
