import React from 'react';
import Navbar from './component/Navbar/Navbar';
import RBanner from './component/Banner/RippleBanner';
import About from './component/About';
import Service from './component/Service';
//import Portfolio from './component/Portfolio/Portfolio';
import Testimonial from './component/Testimonial/Testimonial';
import Clientslogo from './component/Clientslogo';
//import Blog from './component/Blog/Blog';
import Contact from './component/Contact';
//import Skill from './component/Skill/Skill';
import Footer from './component/Footer';
import cmsData from './component/cmsData';

const Home = () => {
  return (
    <div className='body_wrapper'>
      <Navbar mContainer='custome_container' mainlogo='logo2.png' stickylogo='logo.png' />
      <RBanner cmsData={cmsData} imag='turtle.webp' />
      <About aClass='about_area' cmsData={cmsData} />
      <Service wClass='work_area' cmsData={cmsData} />
      {/* <Portfolio /> */}
      {/* <Skill /> */}
      <Clientslogo />
      <Testimonial />
      {/* <Blog /> */}
      <Contact cmsData={cmsData} />
      <Footer cmsData={cmsData} />
    </div>
  );
};
export default Home;
