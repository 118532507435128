import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sectiontitle from '../component/Banner/Sectiontitle';
import { Fade } from 'react-awesome-reveal';

class Service extends Component {
  render() {
    let cmsData = this.props.cmsData;
    var { wClass } = this.props;
    return (
      <section className={`${wClass}`} id='service'>
        <div className='container'>
          <Sectiontitle
            Title='Services'
            TitleP='Aktuelle Schwerpunkte von Projekten im Bereich Softwareentwicklung  :'
          />
          <Fade direction='up' triggerOnce='true' cascade duration={1000} damping={0.4}>
            <div className='row'>
              {cmsData.service &&
                cmsData.service.map((item) => {
                  return (
                    <div className='col-lg-4 col-sm-6' key={item.id}>
                      <div className='work_item wow fadeInUp' data-wow-delay='0.1s'>
                        <i className={item.iconName}></i>
                        <a href='.#'>
                          <h2 className='t_color'>{item.serviceTitle}</h2>
                        </a>
                        <p>{item.sDetails}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}

Service.propTypes = {
  cmsData: PropTypes.node,
  wClass: PropTypes.node,
};

export default Service;
