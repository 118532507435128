// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Flaticon.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Flaticon.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  	Flaticon icon font: Flaticon
  	Creation date: 21/01/2020 18:12
  	*/

@font-face {
  font-family: 'Flaticon';
  /* src: url("./Flaticon.eot"); */
  src:
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
  /* url("./Flaticon.ttf") format("truetype"); */
  /* url("./Flaticon.svg#Flaticon") format("svg"); */
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    /* src: url("./Flaticon.svg#Flaticon") format("svg"); */
  }
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.flaticon-phone:before {
  content: '\\f100';
}
.flaticon-chat:before {
  content: '\\f101';
}
.flaticon-pin:before {
  content: '\\f102';
}
.flaticon-sound:before {
  content: '\\f103';
}
`, "",{"version":3,"sources":["webpack://./src/assets/flaticon/flaticon.css"],"names":[],"mappings":"AAAA;;;IAGI;;AAEJ;EACE,uBAAuB;EACvB,gCAAgC;EAChC;;0DAEuC;EACvC,8CAA8C;EAC9C,kDAAkD;EAClD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE;IACE,uBAAuB;IACvB,uDAAuD;EACzD;AACF;;AAEA;;;;EAIE,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["/*\n  \tFlaticon icon font: Flaticon\n  \tCreation date: 21/01/2020 18:12\n  \t*/\n\n@font-face {\n  font-family: 'Flaticon';\n  /* src: url(\"./Flaticon.eot\"); */\n  src:\n    url('./Flaticon.woff2') format('woff2'),\n    url('./Flaticon.woff') format('woff');\n  /* url(\"./Flaticon.ttf\") format(\"truetype\"); */\n  /* url(\"./Flaticon.svg#Flaticon\") format(\"svg\"); */\n  font-weight: normal;\n  font-style: normal;\n}\n\n@media screen and (-webkit-min-device-pixel-ratio: 0) {\n  @font-face {\n    font-family: 'Flaticon';\n    /* src: url(\"./Flaticon.svg#Flaticon\") format(\"svg\"); */\n  }\n}\n\n[class^='flaticon-']:before,\n[class*=' flaticon-']:before,\n[class^='flaticon-']:after,\n[class*=' flaticon-']:after {\n  font-family: Flaticon;\n  font-size: 20px;\n  font-style: normal;\n  margin-left: 20px;\n}\n\n.flaticon-phone:before {\n  content: '\\f100';\n}\n.flaticon-chat:before {\n  content: '\\f101';\n}\n.flaticon-pin:before {\n  content: '\\f102';\n}\n.flaticon-sound:before {\n  content: '\\f103';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
