import React, { Component } from 'react';
import Clientsliders from 'react-slick';
import Sectiontitle from '../component/Banner/Sectiontitle';

class Clientslogo extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className='clients_logo_area'>
        <div className='container'>
          <Sectiontitle
            Title='Hosting'
            TitleP='Für die Publikation von Cloud-Apps kommen bei aktuellen Projekten nachfolgende Technologie-Stacks zum Einsatz:'
          />
          <Clientsliders {...settings} className='clients_slider'>
            <div className='item'>
              <a href='https://www.docker.com/' target='_blank' rel='noreferrer'>
                <img src={require('../image/docker.png')} alt='Docker' />
              </a>
            </div>
            <div className='item'>
              <a href='https://kubernetes.io/' target='_blank' rel='noreferrer'>
                <img src={require('../image/kubernetes.png')} alt='Kubernetes' />
              </a>
            </div>
            <div className='item'>
              <a href='https://nodejs.org/' target='_blank' rel='noreferrer'>
                <img src={require('../image/node.png')} alt='Node.js' />
              </a>
            </div>
            <div className='item'>
              <a
                href='https://docs.nginx.com/nginx/admin-guide/web-server/reverse-proxy/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={require('../image/nginx.png')} alt='nginx' />
              </a>
            </div>
          </Clientsliders>
        </div>
      </section>
    );
  }
}
export default Clientslogo;
