import React, { Component } from 'react';
import { Fade } from 'react-awesome-reveal';
import Form from './Form';

class Contact extends Component {
  render() {
    let cmsData = this.props.cmsData;
    return (
      <section className='contact-area' id='contact'>
        <div className='container'>
          <div className='row row-reverse'>
            {cmsData.contact &&
              cmsData.contact.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <div className='col-md-5'>
                      <Fade direction='left' triggerOnce duration={500}>
                        <div className='contact_info'>
                          <h4>{item.title}</h4>
                          <ul className='nav'>
                            {item.menuItems.map((info) => {
                              return (
                                <li className='item' key={info.id}>
                                  <div className='media'>
                                    <a href='/#'>
                                      <i className={info.icon}></i>
                                    </a>
                                    <div className='media-body'>
                                      <a href='/#'>{info.text}</a>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Fade>
                    </div>
                  </React.Fragment>
                );
              })}
            <div className='col-md-7'>
              <Fade direction='Right' triggerOnce duration={800}>
                <div className='input_form'>
                  <h4>{cmsData.contacttitle}</h4>
                  <Form />
                  <div id='success'>Your message succesfully sent!</div>
                  <div id='error'>Opps! There is something wrong. Please try again</div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Contact;
