import React from 'react';
import puff from '../image/puff.png';

const NotFound = () => {
  return (
    <div className='body_wrapper'>
      <section
        className='error_area text-center align-items-center d-flex'
        style={{ color: 'black', backgroundColor: 'black' }}
      >
        <div className='container'>
          <div className='error_text'>
            <h3>Page not found</h3>
            <p>Die Seite konnte nicht gefunden werden</p>
            <a className='back_btn' href='/'>
              Home
            </a>
            <h1>404</h1>
            <br />
            <img src={puff} alt='' />
          </div>
        </div>
      </section>
    </div>
  );
};
export default NotFound;
