const cmsData = {
  name: 'cantaluppi.dev',
  name2: 'pascal',
  role: 'Blockchain Development',
  ptext: 'cloud-native app development',
  socialLinks: [
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/pascal-cantaluppi/',
      className: 'social_linkedin',
    },
  ],
  aboutme: 'Aktuelles',
  aboutdetails:
    'Entwicklung von dApps mit Funktionen im Bereich dentralized banking (DeFi)\nWeb3 Operationen mit Smart Contracts - Yield Farming, Staking und Rewards',
  service: [
    {
      id: 1,
      iconName: 'icon-genius',
      serviceTitle: 'Frontend-Development',
      sDetails: 'Entwicklung von modernen JavaScript Webanwendungen mit React und Next.js',
    },
    {
      id: 2,
      iconName: 'icon-mobile',
      serviceTitle: 'Mobile-Development',
      sDetails: 'Entwicklung von Smartphone-Apps mit Framweorks wie Flutter',
    },
    {
      id: 3,
      iconName: 'icon-gears',
      serviceTitle: 'Backend-Development',
      sDetails: 'API und Datenbankentwicklung mit RESTful Webservices und Headless CMS',
    },
    {
      id: 4,
      iconName: 'icon-cloud',
      serviceTitle: 'Cloud-native Apps',
      sDetails: 'Entwicklung von skalierbaren Anwendungen für den betrieb beim Hyperscaler',
    },
    {
      id: 5,
      iconName: 'icon-tools-2',
      serviceTitle: 'Dev-Ops',
      sDetails: 'Dev-Ops Pipelines und automatisiertes Deployment für Agile Softwareentwicklung',
    },
    {
      id: 6,
      iconName: ' icon-magnifying-glass',
      serviceTitle: 'Automated-Testing',
      sDetails: 'Automatisiertes Testen von Software mit Cypress mit Unit- und E2E Tests',
    },
  ],
  contact: [
    {
      id: '1',
      title: 'Kontakt',
      menuItems: [
        {
          id: 1,
          icon: 'icon_pin_alt',
          text: 'Adresse: Basel - Switzerland',
        },
        {
          id: 2,
          icon: 'icon_phone',
          text: 'Telefon: 079 000 00 00',
        },
        {
          id: 3,
          icon: 'icon_mail',
          text: 'Email: info@cantaluppi.ch',
        },
        {
          id: 4,
          icon: 'icon_globe',
          text: 'Website: http://cantaluppi.ch',
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: 'Front-End Developer',
      title: 'Talk is cheap. \n Show me the code',
      discription: 'I design and code beautifully simple things,\n and I love what I do.',
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: 'Introduce',
      aHead: 'Passion \n Heart & Soul',
      aHeadTwo: 'Every great design begin with an even better story',
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: 'Design',
      text: 'Create digital products with unique idea',
      complateProject: '20 Projects',
      icon: 'icon-tools',
    },
    {
      id: 2,
      workName: 'front-End',
      text: 'My coding structure is clean and smooth',
      complateProject: '165 Projects',
      icon: 'icon-genius',
    },
    {
      id: 3,
      workName: 'SEO',
      text: 'Boost your business with SEO optimize.',
      complateProject: '5 Projects',
      icon: 'icon-linegraph',
    },
  ],
  awards: [
    {
      id: 1,
      image: 'logo1.png',
      ctile: 'Site of the year 2020',
      date: 'April 2020',
      description: 'For most favorited design voted by NY City Style customers',
      url: 'http://www.pinterest.com',
      linktext: 'view More',
    },
    {
      id: 2,
      image: 'logo2.png',
      ctile: 'Theme of the day 2021',
      date: 'March 2021',
      description: 'For most favorited design voted by NY City Style customers',
      url: 'http://www.pinterest.com',
      linktext: 'view More',
    },
    {
      id: 3,
      image: 'logo2.png',
      ctile: 'Best design awwards',
      date: 'March 2021',
      description: 'For most favorited design voted by NY City Style customers',
      url: 'http://www.pinterest.com',
      linktext: 'view More',
    },
  ],
  contacttitle: 'Nachricht',
};

export default cmsData;
