import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { Fade } from 'react-awesome-reveal';

class About extends Component {
  render() {
    let cmsData = this.props.cmsData;
    var { aClass } = this.props;
    return (
      <section className={`${aClass}`} id='about'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 d-flex align-items-center'>
              <Fade direction='up' triggerOnce='true' damping={0.1}>
                <div className='about_content'>
                  <h2 className='t_color'>{cmsData.aboutme}</h2>
                  <h6>
                    {cmsData.role}
                    <br />
                    <img
                      src={require('../image/solidity.png')}
                      alt='Solidity'
                      width='150px'
                      style={{ marginTop: '6px' }}
                    />
                  </h6>
                  <p style={{ whiteSpace: 'pre-line' }}>
                    {cmsData.aboutdetails}
                    <br />
                    <img
                      src={require('../image/Ethereum.png')}
                      alt='Ethereum'
                      width='100px'
                      style={{ marginTop: '10px' }}
                    />
                    <br />
                    <small>
                      <strong>
                        <a
                          href='https://app.ens.domains/cantaluppi.eth'
                          target='_blank'
                          rel='noreferrer'
                          style={{ color: 'grey' }}
                        >
                          cantaluppi.eth
                        </a>
                      </strong>
                      <br />
                      <a
                        href='https://etherscan.io/address/0xC2cC4cF44dC236686687f83A3A68606b3933f854'
                        target='_blank'
                        rel='noreferrer'
                        style={{ color: 'grey' }}
                      >
                        0xC2cC4cF44dC236686687f83A3A68606b3933f854
                      </a>
                    </small>
                  </p>

                  {/* <Link to="/" className="theme_btn active">
                    Anfragen
                  </Link> */}
                </div>
              </Fade>
            </div>
            <div className='col-lg-5'>
              <div className='about_img'>
                <Fade direction='right' duration={1500}>
                  <img src={require('../image/scuba.png')} alt='' />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
