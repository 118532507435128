import React, { Component } from 'react';
import Slider from 'react-slick';

class TestimonialSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        <Slider {...settings} className='testimonial_slider'>
          <div className='item'>
            <div className='author_img'>
              <img src={require('../../image/t1.jpg')} alt='' />
            </div>
            <h6>Spongenob Squarepants</h6>
            <span>Schwamm</span>
            <p>
              Digitale Disruption bezieht sich auf den Einfluss neuer digitaler Technologien auf
              bestehende Geschäftsmodelle und Märkte.
              <br />
              Sie kann sowohl Herausforderungen als auch Chancen für Unternehmen darstellen.
            </p>
          </div>
          <div className='item'>
            <div className='author_img'>
              <img src={require('../../image/t2.jpg')} alt='' />
            </div>
            <h6>Patrick Star</h6>
            <span>Seestern</span>
            <p>
              Skalierung in der Cloud bedeutet die flexible Anpassung von Ressourcen und Diensten
              bei einem Hyperscaler.
              <br />
              Sie bietet Vorteile wie Kosteneffizienz, hohe Verfügbarkeit und Flexibilität.
            </p>
          </div>
          <div className='item'>
            <div className='author_img'>
              <img src={require('../../image/t3.jpg')} alt='' />
            </div>
            <h6>Gary Wilson Jr.</h6>
            <span>Schnecke</span>
            <p>Miao!</p>
          </div>
        </Slider>
      </div>
    );
  }
}
export default TestimonialSlider;
