import React, { Component } from 'react';
import { Fade } from 'react-awesome-reveal';
import WaterWave from 'react-water-wave';

class Banner extends Component {
  render() {
    let cmsData = this.props.cmsData;
    let { bClass, textLeft, imag } = this.props;
    return (
      <section id='home'>
        <WaterWave
          strength={500}
          className={`banner_area ${bClass}`}
          style={{ width: '100%', height: '100%', backgroundSize: 'cover' }}
          imageUrl={require('../../image/' + imag)}
        >
          {({ getRootProps }) => (
            <div className='container'>
              <div className={`banner_content ${textLeft}`}>
                <Fade direction='up'>
                  <h5>dive into JavaScript with</h5>
                </Fade>
                <Fade direction='up' duration={1500}>
                  <h2 className='wow fadeInLeft animated'>{cmsData.name}</h2>
                </Fade>
                <Fade direction='up' duration={2200}>
                  <h4 className='wow fadeInUp anoimated'>
                    full-stack and cloud-native development
                  </h4>
                </Fade>
                <ul className='list_style social_icon'>
                  {cmsData.socialLinks &&
                    cmsData.socialLinks.map((item) => {
                      return (
                        <li key={item.name}>
                          <a href={item.url} target='_blank' rel='noreferrer'>
                            <i className={item.className}></i>
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
        </WaterWave>
      </section>
    );
  }
}

export default Banner;
